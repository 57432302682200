
.notFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    padding-top: 0rem;
}
.notFoundTitle {
    font-size: 3rem;
}
