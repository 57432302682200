.bodyContainer {
    border-top-width: 0.1rem;
    border-top-color: rgb(7, 86, 143);
    border-top-style: solid;
    box-shadow: 0 -5px 2px -2px rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #0d6bae;
    padding: 2rem;
}

.footerTitle {
    font-size: 1.5rem;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    font-style: normal;
}

.footerSubtitle {
    font-size: 1.4rem;
    margin-bottom: -8px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    color: black;
    text-decoration: none;
}