.servicesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    padding-top: 0rem;
}

.image {
    border-bottom: 8px solid;
    border-image: linear-gradient(to right, #0d6bae, #AECEE4) 50;
}

.headerBlocks {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.block1 {
    width: 260px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #0d6bae;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 10px;
}

.blockBody {
    width: 340px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.block2 {
    width: 260px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #4b91cb;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 10px;
}

.block3 {
    width: 260px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #8abdea;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 10px;
}

.block4 {
    width: 260px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #c7e4f9;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 10px;
}

.blockText {
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
}

.servicesBody {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.bodySection {
    display: flex;
    border-top: 1px solid gray;
    padding: 10px;
    align-items: center;
}

.bodyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.servicesTitle {
    font-weight: bold;
    color: rgb(13, 106, 174);
    align-self: flex-start;
    font-size: 2rem;
}

.servicesText {
    white-space: pre-line;
    font-size: 1.3rem;
    text-align: justify;
}

.servicesButton {
    border: 2px solid white;
    color: white;
    padding: 10px 20px; 
    text-align: center;
    text-decoration: none;
    display: inline-block; 
    font-size: 16px;
    cursor: pointer;
    background-color: transparent;
}

.servicesButton:hover {
    background-color: #ffffff79;
}