
.contactTitle {
    font-size: 3rem;
    font-weight: bold;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.contactSendButton {
    margin-top: 2rem;
    font-size: 1.3rem;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    border-width: 0.1rem;
    border-style: solid;
    background-color: #0d6bae;
    padding: 0.5rem;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.contactSendButton:hover {
    background-color: #5E9CC9;
}