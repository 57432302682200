
.nav li.active {
    text-decoration: underline;
    text-decoration-color: black;
    text-underline-offset: 5px;
}

.nav li:hover {
    text-decoration: underline;
    text-decoration-color: #D9D9D9;
    text-underline-offset: 5px;
}

.customLink {
    font-size: 1.5rem;
    margin-left: .5rem;
    margin-right: .5rem;
    font-family: "Poppins", sans-serif;
    font-style: normal;
}