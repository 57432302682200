.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 50px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: .3rem;
    border-color: #0d6bae;
    border-radius: 1rem;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1001;
}