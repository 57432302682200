.blogEntryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: .13rem;
    border-color: hsl(177, 51%, 20%);
    margin: 2rem;
    border-radius: 1rem;
    padding: 2rem;
    background-color: hsl(176, 22%, 39%);
    width: 28rem;
    cursor: pointer;
    height: 40rem;
    box-shadow: 0 3px 10px 4px rgba(0, 0, 0, 0.39);
}

.entryDescription {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    height: 8rem;
}

.entryTitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}