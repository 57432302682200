.textInputContainer {
    display: flex;
    flex-direction: column;
}

.textInputLabel{
    margin-bottom: .5rem;
}

.textInputError{
    font-size: 0.8rem;
    margin-top: 0.3rem;
    margin-bottom: 0rem;
}

.textArea {
    resize: vertical;
    min-height: 10rem;
}

.ck-editor__editable_inline:not(.ck-comment__input *) {
    height: 300px;
    overflow-y: auto;
    width: 39.3rem;
}