.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.body {
  display: flex;
  flex-direction: column;
  background-color: hsl(0, 0%, 100%);
  font-family: "Poppins", sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
}

.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 20px;
  top: 47px;
  right: 11px;
}

.bm-burger-bars {
  background: rgb(13, 106, 174);
}

.bm-cross {
  background: rgb(13, 106, 174);
}

.bm-menu {
  background: #ffffff;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item {
  text-decoration: none;
  margin-bottom: 10px;
  color: rgb(13, 106, 174);
}

.bm-item:hover {
  color: white;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.432);
}