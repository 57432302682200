.blogEntriesAdminButton {
    width: 3rem;
    height: 2.2rem;
    font-size: 1rem;
    border-radius: 10px;
    border-color: #cedc00;
    border-width: 0.1rem;
    border-style: solid;
    background-color: #00665e;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.blogEntriesAdminSearchButton {
    width: 2rem;
    height: 1.5rem;
    font-size: 1rem;
    border-radius: 10px;
    border-color: #cedc00;
    border-width: 0.1rem;
    border-style: solid;
    background-color: #00665e;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.blogEntriesAdminButton:hover {
    background-color: #437570;
}