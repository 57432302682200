.blogEntries {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 110rem;
}

.blogEntriesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -2rem;
}

.blogEntriesButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 95%;
    align-items: center;
}

.blogEntriesButton {
    width: 8rem;
    height: 2.2rem;
    font-size: 1rem;
    border-radius: 10px;
    border-color: #cedc00;
    border-width: 0.1rem;
    border-style: solid;
    background-color: #00665e;
    padding: 0.5rem;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.blogEntriesButton:hover {
    background-color: #3c746f;
}