.homeTitle {
    font-size: 3rem;
    font-weight: bold;
}

.homeEntriesTitle {
    font-size: 2.2rem;
    font-weight: bold;
}

.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0rem;
}

.homeHeaderBlocks {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.sectorDiv {
    transition: transform .2s;
    opacity: 70%;
}

.sectorDiv:hover {
    transform: scale(1.2);
    opacity: 100%;
}

.homeSectorsText {
  font-family: "Poppins", sans-serif;
  color: #0d6bae;
  font-weight: bold;
  background-color: white;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 10px;
}