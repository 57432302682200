.AuthModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00665e;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: .1rem;
    border-color: #cedc00;
    border-radius: 1rem;
    
}

.authSendButton {
    margin-top: 2rem;
    font-size: 1.5rem;
    border-radius: 10px;
    border-color: #cedc00;
    border-width: 0.1rem;
    border-style: solid;
    background-color: #00665e;
    padding: 0.5rem;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.genericModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000;
}

.divCloseModal {
    display: flex;
    justify-content: flex-end;
}

.divCloseModal button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: rgb(221, 221, 221);
}

.modalHeaderDiv {
    background-color: #0c3633;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    box-shadow: 0 5px 2px -2px rgba(0, 0, 0, .2);
}