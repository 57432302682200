.checkBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkBoxLabel {
    font-size: 1rem;
    margin-left: 1rem;
}

.checkBoxInput {
    transform: scale(1.5);
    cursor: pointer;
}

.checkBoxError {
    font-size: 0.8rem;
    margin-top: -1rem;
}

.checkBoxComponentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}