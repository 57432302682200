.dropDownProfile {
    position: absolute;
    width: 120px;
    padding: 10px;
    border-radius: 15px;
    background-color: #004d47;
    border: 1px solid #cedc00;
    list-style-type: none;
}

.dropDownProfile::before {
    position: absolute;
    width: 20px;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    height: 20px;
    transform: rotate(45deg);
}

.dropDownProfileItem:hover {
    background-color: #47615f;
}

.tableLineActive:hover {
    background-color: #437570;
}

.tableLineNotActive {
    background-color: #fbffbd9f;
}

.tableLineNotActive:hover {
    background-color: #faffc385;
}

.tableLineScheduled {
    background-color: #5482ff63;
}

.tableLineScheduled:hover {
    background-color: #5482ff54;
}