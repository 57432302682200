.nav {
    position: sticky;
    top: 0;
    background-color: hsl(0, 0%, 100%);
    color: white;
    border-bottom-width: 0.1rem;
    border-bottom-color: rgb(13, 106, 174);
    border-bottom-style: solid;
    box-shadow: 0 5px 2px -2px rgba(0, 0, 0, .2);
    z-index: 999;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .8rem;
    padding-bottom: .3rem;
}

.preHeader {
    display: flex;
    flex-direction: row;
    background-color: rgb(13, 106, 174);
    height: 30px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.nav a {
    color: rgb(13, 106, 174);
    text-decoration: none;
    display: flex;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.site-title {
    font-size: 1rem;
}

.headerRightContainer {
    display: flex;
    align-items: center;
}

.flag {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
}


